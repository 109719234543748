$(function(){
  setTimeout(function() {
    $('.modal-container').fadeIn()
    .promise()
    .done(function(){
      $('input.autofocus').focus();
    });
  }, 100);

  window.Parsley.setLocale($('html').attr('lang'));

  if ($('form.form-parsley').length > 0){
    $('form.form-parsley').parsley();
  }
});
